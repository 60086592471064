<template>
  <div class="overflow-y-scroll scrollbar-hide h-screen">
    <div class="back-btn-wrapper">
      <button class="back-btn" @click="()=> this.$router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
          <defs>
            <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="back" transform="translate(-308 -28)">
            <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
              <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                  <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                </g>
              </g>
            </g>
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
          </g>
        </svg>
      </button>
    </div>
    <div class="top-profile mt-40">
<!--      <div class="share-btn mb-4 ml-12">
        انتشار
        <svg xmlns="http://www.w3.org/2000/svg" width="45.531" height="47.75" viewBox="0 0 45.531 47.75">
          <path id="Icon_awesome-share-alt" data-name="Icon awesome-share-alt" d="M12.2,11.094a3.314,3.314,0,0,0-2.073.724L6.577,9.6a3.347,3.347,0,0,0,0-1.445L10.13,5.932A3.322,3.322,0,1,0,8.954,4.051L5.4,6.271a3.328,3.328,0,1,0,0,5.207L8.954,13.7A3.328,3.328,0,1,0,12.2,11.094Z" transform="translate(15 15)" fill="#fff"/>
        </svg>
      </div>-->
      <div class="bg-profile" style="top: -16px !important;"></div>
    </div>
    <div class="relative m-auto z-10 mb-28">
      <div class="flex flex-col justify-center items-center">
        <img class="w-24 h-24 rounded-full mb-4 logo-round" :src="baseURL + logo" alt="">
        <h5 style="white-space: nowrap ; top:-45px; font-size : 20px; color: #fff" >بنگاه {{name}}</h5>
      </div>
      <div class="mx-4 mb-5">
        <div class="tab-profile-bongah">
          <ul>
            <li :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1"><button>مشخصات</button></li>
            <li :class="activeTab === 2 ? 'active' : ''" @click="activeTab = 2"><button>لیست املاک</button></li>
            <li :class="activeTab === 3 ? 'active' : ''" @click="activeTab = 3"><button>نظرات</button></li>
          </ul>
        </div>
        <Details
            :activity="activity"
            :phone="phone"
            :licensePicture="license_picture"
            v-if="activeTab === 1"
        />
        <Estates v-if="activeTab === 2" />
        <Comments v-if="activeTab === 3" />

      </div>
    </div>
    <div class="contact-us w-full fixed bottom-0 flex justify-center py-2 z-50">
      <a :href="`tel:${phone}`" class="btn-custom w-4/5">
        تماس با بنگاه
        <span class="svg-btn-icon bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.879" height="20.877" viewBox="0 0 20.879 20.877">
  <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call" d="M24.766,20.85A17.537,17.537,0,0,0,21.1,18.4c-1.1-.527-1.5-.516-2.277.043-.647.467-1.065.9-1.81.739a10.8,10.8,0,0,1-3.636-2.69,10.72,10.72,0,0,1-2.69-3.636c-.158-.75.277-1.163.739-1.81.56-.777.576-1.179.043-2.277A17.19,17.19,0,0,0,9.02,5.1c-.8-.8-.978-.625-1.419-.467a8.073,8.073,0,0,0-1.3.69,3.919,3.919,0,0,0-1.56,1.647c-.31.669-.669,1.913,1.158,5.164a28.812,28.812,0,0,0,5.066,6.756h0l.005.005.005.005h0a28.925,28.925,0,0,0,6.756,5.066c3.25,1.826,4.495,1.468,5.164,1.158a3.852,3.852,0,0,0,1.647-1.56,8.073,8.073,0,0,0,.69-1.3C25.391,21.828,25.57,21.649,24.766,20.85Z" transform="translate(-4.49 -4.503)" fill="#fff"/>
</svg>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Details from "./Details";
import Estates from "./Estates";
import Comments from "./Comments";
import axios from "../../plugins/axios";
export default {
  name: "RealEstatePage",
  components :{
    Estates,
    Details,
    Comments
  },
  data(){
    return{
      activeTab : 1,
      activity : null,
      name : null,
      phone :null,
      logo : null,
      license_picture : null,
      marker : [],
      coordinates : [],
      baseURL: this.$store.state.general.baseURL
    }
  },
  async created() {
    let id = this.$route.params.id
    axios.get('real-estates/' + id).then(({data}) => {
      this.activity = data.data.field_of_activity
      this.name = data.data.name;
      this.phone = data.data.telephone;
      this.logo = data.data.logo;
      this.license_picture = data.data.license_picture;
    })
  }
}
</script>

<style>

</style>