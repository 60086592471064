<template>
  <div class=" product_wrapper">
      <multiselect
          class="select--activity"
          v-model="value"
          :options="activity"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          placeholder="همه"
          @select="handleFilter"
      >
      </multiselect>
    <template v-if="dataWithFilter.length > 0">
      <router-link :to="{name : 'EstateRealEstate' , params :{ id : estate.id}}" class="product" v-for="estate in dataWithFilter" :key="estate.id">
        <div class="image-section">
          <img class="image-product" :src="baseURL + estate.thumbnail.link" alt="">
          <div class="top">
            <span>{{estate.advertisement_type}}</span>
            <img :src="baseURL + estate.realestate.logo" alt="">
          </div>
          <div class="bottom">
            <a @click="handleVideo(estate.video_link)">مشاهده ویدئو
              <svg xmlns="http://www.w3.org/2000/svg" width="13.634" height="13.634" viewBox="0 0 13.634 13.634">
                <g id="Group_12813" data-name="Group 12813" transform="translate(-62.183 -563.183)">
                  <g id="instagram" transform="translate(62.183 563.183)">
                    <path id="Path_17917" data-name="Path 17917" d="M9.942,0H3.69A3.7,3.7,0,0,0,0,3.691V9.944A3.7,3.7,0,0,0,3.69,13.634H9.943a3.7,3.7,0,0,0,3.691-3.691V3.691A3.7,3.7,0,0,0,9.942,0Zm2.891,9.944a2.9,2.9,0,0,1-2.891,2.891H3.69A2.9,2.9,0,0,1,.8,9.944V3.691A2.9,2.9,0,0,1,3.69.8H9.943a2.9,2.9,0,0,1,2.891,2.891Zm0,0" transform="translate(0.001 0)" fill="#ffffffb5"/>
                    <path id="Path_17918" data-name="Path 17918" d="M119.732,116a3.728,3.728,0,1,0,3.728,3.728A3.732,3.732,0,0,0,119.732,116Zm0,6.657a2.929,2.929,0,1,1,2.929-2.929A2.932,2.932,0,0,1,119.732,122.661Zm0,0" transform="translate(-112.915 -112.915)" fill="#ffffffb5"/>
                    <path id="Path_17919" data-name="Path 17919" d="M359.078,66.285a1.1,1.1,0,1,0,1.1,1.1A1.1,1.1,0,0,0,359.078,66.285Zm0,1.4a.3.3,0,1,1,.3-.3A.3.3,0,0,1,359.078,67.689Zm0,0" transform="translate(-348.444 -64.52)" fill="#ffffffb5"/>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div class="detail-section">
          <div class="top">
            <p>{{ estate.title }}</p>
            <p v-if="estate.advertisement_type_en === 'rent' || estate.advertisement_type_en === 'mortgageRent'" class="text-white text-left">{{( '' +estate.options.downPayment).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}<span>تومان</span></p>
 <p v-else class="text-white text-left ">{{( '' +estate.options.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}  <span>تومان</span> </p>

          </div>
          <div class="bottom">
            <div>
              <svg id="Group_12590" data-name="Group 12590" xmlns="http://www.w3.org/2000/svg" width="17.521"
                   height="11.136" viewBox="0 0 17.521 11.136">
                <g id="bed">
                  <path id="Path_9" data-name="Path 9"
                        d="M57.482,91.355A1.66,1.66,0,0,0,55.824,89.7H52.1a1.658,1.658,0,0,0-1.385.747,1.658,1.658,0,0,0-1.385-.747H45.609a1.66,1.66,0,0,0-1.658,1.658v.076h-.415V89.814a1.822,1.822,0,0,1,1.82-1.82H56.077a1.822,1.822,0,0,1,1.82,1.82v1.617h-.415v-.076Z"
                        transform="translate(-41.955 -87.994)" fill="#ff7400"/>
                  <path id="Path_10" data-name="Path 10"
                        d="M.7,204.076H16.824a.7.7,0,0,1,.7.7v4.046a.7.7,0,0,1-.7.7h-.29l.343.968a.387.387,0,1,1-.73.259l-.434-1.226H1.809l-.434,1.226a.387.387,0,0,1-.73-.259l.343-.968H.7a.7.7,0,0,1-.7-.7v-4.046A.7.7,0,0,1,.7,204.076Z"
                        transform="translate(0 -199.864)" fill="#ff7400"/>
                  <path id="Path_11" data-name="Path 11"
                        d="M259.87,156.262a.885.885,0,0,1,.884.884v.076h-5.491v-.076a.885.885,0,0,1,.884-.884Z"
                        transform="translate(-252.494 -153.785)" fill="#ff7400"/>
                  <path id="Path_12" data-name="Path 12"
                        d="M80.932,156.262a.885.885,0,0,1,.884.884v.076H76.325v-.076a.885.885,0,0,1,.884-.884Z"
                        transform="translate(-67.064 -153.785)" fill="#ff7400"/>
                </g>
              </svg>
              <span>{{ estate.options.number_of_bedrooms }} خوابه</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.776" height="12.398" viewBox="0 0 13.776 12.398">
                <path id="ic_business_24px"
                      d="M8.888,5.755V3h6.888V15.4H2V5.755ZM13.02,14.02H14.4V12.643H13.02Zm0-2.755H14.4V9.888H13.02Zm0-2.755H14.4V7.133H13.02Zm0-2.755H14.4V4.378H13.02ZM10.265,14.02h1.378V12.643H10.265Zm0-2.755h1.378V9.888H10.265Zm0-2.755h1.378V7.133H10.265Zm0-2.755h1.378V4.378H10.265ZM3.378,14.02h5.51V12.643H7.51V11.265H8.888V9.888H7.51V8.51H8.888V7.133H3.378ZM4.755,8.51H6.133V9.888H4.755Zm0,2.755H6.133v1.378H4.755Z"
                      transform="translate(-2 -3)" fill="#ff7400"/>
              </svg>
              <span>  {{ estate.options.age }} ساله</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="12.398" height="12.398" viewBox="0 0 12.398 12.398">
                <path id="ic_format_shapes_24px"
                      d="M13.4,4.381V1H10.017V2.127H4.381V1H1V4.381H2.127v5.635H1V13.4H4.381V12.271h5.635V13.4H13.4V10.017H12.271V4.381ZM2.127,2.127H3.254V3.254H2.127ZM3.254,12.271H2.127V11.144H3.254Zm6.763-1.127H4.381V10.017H3.254V4.381H4.381V3.254h5.635V4.381h1.127v5.635H10.017Zm2.254,1.127H11.144V11.144h1.127ZM11.144,3.254V2.127h1.127V3.254Z"
                      transform="translate(-1 -1)" fill="#ff7400"/>
              </svg>
              <span>{{ estate.options.area }}متر</span>
            </div>
          </div>
        </div>
      </router-link>
    </template>
    <div class="flex justify-center items-center h-96" v-else>
      <h3 class="text-white ">ملکی وجود ندارد</h3>
    </div>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import Multiselect from 'vue-multiselect'

export default {
  name: "RealEstatePageEstate",
  components :{
    Multiselect
  },
  data(){
    return{
      data : null,
      baseURL : this.$store.state.general.baseURL,
      value: '',
      activity: ['همه'],
      dataWithFilter : null

    }
  },
  methods:{
    handleVideo(data){
      window.open(data);
    },
    handleFilter(value){
      if (value !== 'همه'){
        this.dataWithFilter = this.data.filter((item) =>{
          return  item.advertisement_type === value
        })
      }else{
        this.dataWithFilter = this.data
      }

    }
  },
  mounted() {
    let id = this.$route.params.id;
    axios.post('/real-estates/'+ id  +'/estates').then(({data})=>{
      this.data = data.data.data;
      this.dataWithFilter = data.data.data
    })
    axios.get('real-estates/' + id).then(({data}) => {
      this.activity.push(...data.data.field_of_activity);
    })
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>