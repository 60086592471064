<template>
  <div>
    <Page />
  </div>
</template>

<script>
import Page from '../../components/realEstatePage/index'
export default {
  name: "RealEstatePage",
  components: {
    Page,
  }
}
</script>

<style scoped>

</style>